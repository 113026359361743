import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Roost/Banner"
import OurDifference from "../components/Index/OurDifference"
import Rates from "../components/Index/Rates"
import Lender from "../components/Index/Lender"
import CTABanner from "../components/Common/CTABanner"
import TopCalculators from "../components/Common/TopCalculators"
import SEO from "../components/App/SEO"
import Partners from "../components/Index/Partners"
import { graphql } from "gatsby"

export const query = graphql`
  query($id: String!) {
    allStrapiPartner(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          Name
          logo {
            url
          }
          Slug
          Code
          Description
        }
      }
    }
  }
`

const Partner = ({data}) => {
  var Partner = data.allStrapiPartner.edges[0].node;
  // console.log("data", data);
  // console.log("Partner", Partner);
  return (
    <Layout>
      <SEO
        title={Partner.Name + " | Oxygen - Home Loans Made Simple"}
        description={Partner.Description}
      />
      <Navbar />
      <Banner partner={Partner}/>
      <Rates />
      <Lender />
      <OurDifference />
      <div className="pt-5 pb-70">
        <TopCalculators sectionTitle="Calculators" showMore="true" />
      </div>
      <Partners/>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Partner


