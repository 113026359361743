const SourceIdentifier = source => {
  switch (source) {
    case "Carclarity":
      return "PartnerCarClarity"
    case "Facebook":
      return "PartnerFacebook"
    case "GCS":
      return "PartnerLandingGCS"
    case "Mcgrath":
      return "PartnerMcGrath"
    case "Mcg_coburg":
      return "PartnerMcGrathCoburg"
    case "Mcg_collaroy":
      return "PartnerMcGrathCollaroy"
    case "Mcg_croydon":
      return "PartnerLandingMcGrathCroydon"
    case "Mcg_geelong":
      return "PartnerLandingMcGrathGeelong"
    case "Roost":
      return "PartnerLandingRoost"
    case "Ratecity":
      return "PartnerLandingRateCity"
    default:
      return "Oxygen Web Site"
  }
}

export default SourceIdentifier
