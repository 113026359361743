import React from "react"
import { Link } from "gatsby"
import badgeImg from "../../assets/images/abm-badge.png"
import badgeABA from "../../assets/images/about/img-finalist-aba-award-20222.png"
import logoRoost from "../../assets/images/partner-pages/logo-roost.svg"

const Roostbadge = (props) => {

  return (
    <div className="wr-badge mb-5 mb-md-4 mb-xl-4">
        <div className="container">
            <img src={badgeImg} alt="ABW 5-Star Mortgage Innovator 2022 badge" />
            <img src={badgeABA} className="ml-1" alt="Finalist ABA Award 2022 Badge" />
            <div className="partner-container">
              <span>Valued Partner</span>
              <img src={props.parnerBadge} alt="logo-partner" className="rate-city-img"/>
            </div>
        </div>
    </div>
  )
}

export default Roostbadge
